export default {
    coreMenuObj: {
        defaultMenu: {
            home: 'coreMenuObj.defaultMenu.home'
        }
    },
    sysInfo: {
        sysIntro: 'sysInfo.sysIntro',
        sysName: 'sysInfo.sysName'
    },
    requestInfo: {
        withoutUrl: 'requestInfo.withoutUrl'
    },
    tagObj: {
        closeAll: 'tagObj.closeAll',
        closeCur: 'tagObj.closeCur',
        closeOther: 'tagObj.closeOther',
        closeRight: 'tagObj.closeRight',
        singlePage: 'tagObj.singlePage',
        refreshPage: 'tagObj.refreshPage',
        affixPage: 'tagObj.affixPage',
        cancelAffixPage: 'tagObj.cancelAffixPage',
        errorTip: {
            homePageCloseError: 'tagObj.errorTip.homePageCloseError',
            affixPageCloseError: 'tagObj.errorTip.affixPageCloseError',
            homePageAffixError: 'tagObj.errorTip.homePageAffixError'
        },
        checkSave: 'tagObj.checkSave',
        cancelClose: 'tagObj.cancelClose'
    },
    loginForm: {
        byAccountBtn: 'loginForm.byAccountBtn',
        byPhoneBtn: 'loginForm.byPhoneBtn',
        accountPlaceholder: 'loginForm.accountPlaceholder',
        passwordPlaceholder: 'loginForm.passwordPlaceholder',
        phonePlaceholder: 'loginForm.phonePlaceholder',
        validPlaceholder: 'loginForm.validPlaceholder',
        validBtn: 'loginForm.validBtn',
        autoLoginPlaceholder: 'loginForm.autoLogin',
        forgetBtn: 'loginForm.forgetBtn',
        loginBtn: 'loginForm.loginBtn',
        accountEmptyErr: 'loginForm.byAccountTip.emptyErr',
        passwordEmptyErr: 'loginForm.byPasswordTip.emptyErr',
        validEmptyErr: 'loginForm.byValidTip.emptyErr',
        repeatSendMsgWarn: 'loginForm.sendMsg.repeatErr',
        getMsgSuccess: 'loginForm.sendMsg.success',
        getMsgTip: 'loginForm.sendMsg.codeTip',
        loginSuccess: 'loginForm.loginSuccess'
    },
    personalCenter: {
        frontendLog: 'personalCenter.frontendLog',
        customSetting: 'personalCenter.customSetting',
        logout: 'personalCenter.logout',
        customClean: 'personalCenter.customClean',
        errorTip: {
            cleanSuccess: 'personalCenter.errorTip.cleanSuccess'
        }

    },
    errorPage: {
        errorTip: {
            notfoundTip: 'errorPage.errorTip.notfoundTip',
            notrightTip: 'errorPage.errorTip.notrightTip',
            noserviceTip: 'errorPage.errorTip.noserviceTip'
        },
        btnText: 'errorPage.btnText'
    },
    searchPanel: {
        btn: {
            search: 'searchPanel.btn.search',
            shrink: 'searchPanel.btn.shrink',
            unshrink: 'searchPanel.btn.unshrink',
            reset: 'searchPanel.btn.reset'
        }
    },
    logline: {
        list: 'logline.list',
        detail: 'logline.detail',
        btnList: {
            export: 'logline.btnList.export',
            remove: 'logline.btnList.remove'
        },
        time: {
            plh: 'logline.time.plh',
            range: 'logline.time.range',
            halfDay: 'logline.time.halfDay',
            oneDay: 'logline.time.oneDay',
            weekDay: 'logline.time.weekDay',
            monthDay: 'logline.time.monthDay'
        },
        type: {
            logType: 'logline.type.logType',
            plh: 'logline.type.plh'
        },
        tableColumns: {
            index: 'logline.tableColumns.index',
            desc: 'logline.tableColumns.desc',
            type: 'logline.tableColumns.type',
            time: 'logline.tableColumns.time',
            op: 'logline.tableColumns.op'
        },
        request: 'logline.request',
        error: {
            cancel: 'logline.error.cancel',
            confirm: 'logline.error.confirm'
        },
        success: 'logline.success'
    },
    comTable: {
        columns: {
            title: 'comTable.columns.title',
            all: 'comTable.columns.all',
            reset: 'comTable.columns.reset',
            fixedLeft: 'comTable.columns.fixedLeft',
            fixedRight: 'comTable.columns.fixedRight',
            cancelFixed: 'comTable.columns.cancelFixed'
        },
        size: {
            title: 'comTable.size.title',
            small: 'comTable.size.small',
            normal: 'comTable.size.normal',
            large: 'comTable.size.large'
        },
        fullscreen: 'comTable.fullscreen',
        unFullscreen: 'comTable.unFullscreen'
    },
    normalBtn: {
        detail: 'normalBtn.detail'
    },
    setting: {
        customEntry: 'setting.customEntry',
        systemSwitch: 'setting.systemSwitch',
        isMock: 'setting.isMock',
        isHotKey: 'setting.isHotKey',
        isBreadCrumb: 'setting.isBreadCrumb',
        isCache: 'setting.isCache',
        isI18n: 'setting.isI18n'
    },
    pagingInfo: {
        total: 'pagingInfo.total',
        uint: 'pagingInfo.uint',
        items: 'pagingInfo.items'
    }
}
