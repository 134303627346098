import coreLocale from '@corets/locale'

export default {
    localeObj: {
        ...coreLocale,
        menuObj: {
            errorTip: {
                notfoundTip: 'menuObj.errorTip.notfoundTip',
                emptyErr: 'menuObj.errorTip.emptyErr'
            }
        }
    },
    commonObj: {
        loglinePath: 'logline/Logline',
        settingPath: 'setting/Setting',
        loginForm: {
            sendMsgGap: 10
        },
        copyright: {
            githubUrl: 'https://github.com/yiptsangkin/near-admin-vue'
        },
        shrinkThresholdValue: 800,
        tablePagingInfoOpt: {
            current: 1,
            pageSize: 10,
            total: 0,
            'show-size-changer': true,
            pageSizeOptions: ['10', '30', '50'],
            showTotal (total: any, range: any) {
                return `第 ${range[0]}-${range[1]} 条 共 ${total} 条记录`
            }
        },
        localStorageKey: ['nearAdminCustomerEntry', 'nearAdminCacheConfig', 'nearAdminTagIndex', 'nearAdminTagList', 'nearAdminLang'],
        basePath: window.location.pathname.split('/')[1],
        defaultAvatar: '/static/images/party/default_avatar.png'
    },
    organization: {
        organType: 'ORG_TYPE',
        organState: 'ORG_STATE',
        unitType: 'UNIT_TYPE',
        unitState: 'UNIT_STATE',
        position: 'POSITION',
        postType: 'POST_TYPE',
        postInfo: 'POST_INFO'
    },
    member: {
        sex: 'SEX',
        nation: 'NATION',
        boolean: 'BOOLEAN',
        post: 'POST',
        memberStatus: 'MEMBER_STATUS',
        memberType: 'MEMBER_TYPE',
        education: 'EDUCATION',
        partyStatus: 'PARTY_STATUS'
    },
    classInfo: {
        changeType: 'CHANGE_TYPE'
    },
    assess: {
        period: 'ASSESS_PERIOD',
        assessType: 'ASSESSMENT_TYPE'
    },
    fee: {
        feeType: 'FEE_TYPE',
        payType: 'PAY_TYPE',
        payStatus: 'PAY_STATUS'
    },
    newsDict: {
        timeNews: 'eaa01d70e3e54eeb9e812393030ad031',
        provinceNews: '3931183002f740d4bd3fe173d71ff18a',
        workMoveNews: '66024627b29c44f18718a0f2cb8db945',
        organMoveNews: '00e9d67a66ad465aa9d14daa93221e7a',
        notifyNews: '8735ab4d11824ffd919985fdb4d83815',
        knowledgeNews: 'b80bf74aefa54348b163abef24cf67a3',
        frontOrganNew: '7e30b2a8816e408b9c13c7a00160f2db',
        memberNew: '1730dd33fbf749d58a32ca039533d70e',
        workCulture: `b37f82f92c8f45cb995a5439c3a28798`,
        otherStone: 'af926f8760954085a5cc63bad46632ff',
        sideBlock: '445d04f936534b2696b1c2864edebf54',
        importantNews: 'e5013cdc0e1c49ed876e392d8c5915eb',
        firstLineDynamics: '7b7c3365c88a42d7b69e1a6bb6501fc1'
    },
    article: {
        articleStatus: 'ARTICLE_STATUS'
    }
}
