import comConfig from '@custom/config';

const apiHost = comConfig.apiHost

export default {
    getSendMsg: `${apiHost}/mock-data/getSendMsg`,
    checkLoginByAccount: `${apiHost}/webapi/admin/auth/login`,
    checkLoginByPhone: `${apiHost}/mock-data/checkLoginByPhone`,
    getCurrentUser: `${apiHost}/webapi/admin/sysUser/getCurrentUser`,
    logoutUser: `${apiHost}/webapi/admin/auth/logout`,
    login: `${apiHost}/webapi/admin/auth/login`
}
