import apiUrl from './apiUrl'
import utils from '@corets/utils'
import {ReqType, ResType} from '@corets/type'

const getUserMenu = (reqType: ReqType) => {
    reqType.method = 'GET'
    reqType.url = apiUrl.getUserMenu
    const result = utils.sendReq(reqType)
    return result
}

const getUserInfo = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.getUserInfo
    const result = utils.sendReq(reqType)
    return result
}

const uploadFile = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.uploadFile
    reqType.headers = {}
    const result = utils.sendReq(reqType)
    return result
}

const queryMessage = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.queryMessage
    const result = utils.sendReq(reqType)
    return result
}

const auditMod = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.auditMod
    const result = utils.sendReq(reqType)
    return result
}
const notifyHandle = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.notifyHandle
    const result = utils.sendReq(reqType)
    return result
}

const handleMessage = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.handleMessage
    const result = utils.sendReq(reqType)
    return result
}

const notifyDetail = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.notifyDetail
    const result = utils.sendReq(reqType)
    return result
}

const notifyRead = (reqType: ReqType) => {
    reqType.method = 'POST'
    reqType.url = apiUrl.notifyRead
    const result = utils.sendReq(reqType)
    return result
}

export default {
    getUserMenu,
    getUserInfo,
    uploadFile,
    queryMessage,
    auditMod,
    notifyHandle,
    handleMessage,
    notifyDetail,
    notifyRead
}
