import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@store/index'
import i18n from '@corets/lang'
import mock from '@mock/index'
import moment from 'moment'

// antd component
import {
    Modal,
    Row,
    Col,
    Carousel,
    Icon,
    List,
    Dropdown,
    Menu,
    Skeleton,
    Breadcrumb,
    Avatar,
    Input,
    Button,
    Table,
    Tabs,
    Form,
    Space
} from 'ant-design-vue'


import {
    ConfigProvider,
    message,
    notification
} from 'ant-design-vue'
import comConfig from '@custom/config'
import utils from '@corets/utils'

// config
Vue.config.productionTip = false

// import antd component
Vue.component(ConfigProvider.name, ConfigProvider)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Carousel.name, Carousel)
Vue.component(Icon.name, Icon)
Vue.component(List.name, List)
Vue.component(List.Item.name, List.Item)
Vue.component(List.Item.Meta.name, List.Item.Meta)
Vue.component(List.Item.Meta.name, List.Item.Meta)
Vue.component(Menu.name, Menu)
Vue.component(Dropdown.name, Dropdown)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Skeleton.name, Skeleton)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Breadcrumb.Item.name, Breadcrumb.Item)
Vue.component(Avatar.name, Avatar)
Vue.component(Input.name, Input)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Input.Password.name, Input.Password)
Vue.component(Button.name, Button)
Vue.component(Table.name, Table)
Vue.component(Tabs.name, Tabs)
Vue.component(Tabs.TabPane.name, Tabs.TabPane)
Vue.component(Form.name, Form)
Vue.component(Form.Item.name, Form.Item)
Vue.component(Space.name, Space)

Vue.use(Modal as any)

// import antd directive
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$moment = moment
Vue.prototype.$confirm = Modal.confirm

// reset `$t` directive when isI18n switch is on
if (!comConfig.buildSwitch.isI18n) {
    Vue.prototype.$t = (str: string) => {
        return utils.getLocaleIfI18nOff(str)
    }
}

// mockjs
if (comConfig.buildSwitch.isMock) {
    Vue.use(mock as any)
}

new Vue({
    render: (h) => h(App),
    store,
    router,
    i18n
}).$mount('#app')
