// @ts-nocheck

import api from '@api/manage/apiMethod'
import authApi from '@api/auth/apiMethod'
import {mapActions} from 'vuex'
import utils from '@corets/utils'

const DEFAULT_MALE_AVATAR = `/static/images/common/default_handsome.jpg`
const DEFAULT_FEMALE_AVATAR = `/static/images/common/default_beauty.jpg`

export default {
    methods: {
        ...mapActions([
            'changeMenu',
            'changeUserInfo',
            'changeRightPathList'
        ]),
        setCookie(cname: any, cvalue: any, attrs: any){
            attrs.expires = attrs.expires ? attrs.expires.toUTCString() : ''
            let attributes = ''
            for (const key of Object.keys(attrs)) {
                const item = attrs[key]
                if (item) {
                    attributes += '; ' + key
                    if (item !== true) {
                        attributes += '=' + item
                    }
                }
            }
            document.cookie = cname + '=' + cvalue + attributes
        },
        async getUserInfo () {
            const self = this as any
            await authApi.getCurrentUser({
                success (res) {
                    let params = {
                        user: res,
                        oauthToken: localStorage.getItem('token')
                    }
                    self.setCookie(
                        'userInfo', 
                        encodeURIComponent(JSON.stringify(params)), 
                        { path: '/'}
                    )
                    localStorage.setItem('cacheMode', 'cookies')
                    self.changeUserInfo({
                        avatar: DEFAULT_MALE_AVATAR,
                        userName: res.username,
                        role: res.roleId,
                        roleName: res.roleName,
                        gender: res.sex,
                        ...res
                    })
                    localStorage.setItem('cacheUserInfo', JSON.stringify({
                        avatar: DEFAULT_MALE_AVATAR,
                        userName: res.username,
                        role: res.roleId,
                        roleName: res.roleName,
                        gender: res.sex,
                        ...res
                    }))
                },
                error () {
                    // 门户的部分接口不做登录处理
                    localStorage.clear()
                }
            })
        },
        setRightPath (menuObj) {
            const self = this
            // to set right path for visitor, not in the right path will show 403 page
            const rightPathList = utils.getMenuRootCp(menuObj.menuList)
            const uniqueRightPathSet = new Set(rightPathList)
            self.changeRightPathList([...uniqueRightPathSet])
        },
        getUserMenu () {
            const self = this as any
            api.getUserMenu({
                success (res: any) {
                    const newMenuList = self.formatUserMenu(res)
                    self.changeMenu({
                        menuList: newMenuList
                    })
                    self.setRightPath({
                        menuList: newMenuList
                    })
                },
                error () {
                    // 门户的部分接口不做登录处理
                }
            })
        },
        formatUserMenu (menuList: any[]) {
            const self = this
            if (menuList) {
                menuList.forEach((item) => {
                    const temChildren = self.formatUserMenu(item.children)
                    item.child = temChildren || []
                    item.name = item.menuName
                    item.path = item.router

                })
                return  menuList
            } else {
                return []
            }
        }
    },
    created () {
        const self = this as any
        self.getUserMenu()
        self.getUserInfo()
    }
}
