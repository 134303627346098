import Vue from 'vue'
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router'
import utils from '@corets/utils'
import dict from '@custom/dict'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const HomePage = () => import('../view/home/HomePage.vue')
const ArticleList = () => import('../view/article/ArticleList.vue')
const ActivityList = () => import('../view/activity/ActivityList.vue')
const ArticleDetail = () => import('../view/article/ArticleDetail.vue')
const ActivityDetail = () => import('../view/activity/ActivityDetail.vue')
const MessageList = () => import('../view/message/MessageList.vue')
const FrontLineNew = () => import('../view/article/FrontLineNew.vue')

Vue.use(VueRouter)

const routesConfig: RouteConfig[] = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/articlelist',
        name: 'ArticleList',
        component: ArticleList
    },
    {
        path: '/activitylist',
        name: 'ActivityList',
        component: ActivityList
    },
    {
        path: '/articledetail',
        name: 'ArticleDetail',
        component: ArticleDetail
    },
    {
        path: '/activitydetail',
        name: 'ActivityDetail',
        component: ActivityDetail
    },
    {
        path: '/messageList',
        name: 'MessageList',
        component: MessageList
    },
    {
        path: '/frontLineNew',
        name: 'FrontLineNew',
        component: FrontLineNew
    }
]
const basePath = dict.commonObj.basePath

const routerOpt: RouterOptions = {
    mode: 'history',
    base: `/${basePath}`,
    routes: routesConfig
}

const routeObj = new VueRouter(routerOpt)
routeObj.beforeEach((to: any, from, next) => {
    NProgress.start()
    const title = to.meta.title
    if (title) {
        utils.setPageTitle(title)
    } else {
        utils.setPageTitle('')
    }
    next()
})

routeObj.afterEach((transition: any) => {
    NProgress.done()
})

export default routeObj
