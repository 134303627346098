import comConfig from '@custom/config';

const apiHost = comConfig.apiHost

export default {
    getUserMenu: `${apiHost}/webapi/admin/sysMenu/getMenuListByLoginUser`,
    getUserInfo: `${apiHost}/mock-data/getUserInfo`,
    uploadFile: `${apiHost}/webapi/cosUpload/picture`,
    queryMessage: `${apiHost}/webapi/admin/notify/list`,
    auditMod: `${apiHost}/webapi/admin/org/audit`,
    notifyHandle: `${apiHost}/webapi/admin/notify/handle`,
    notifyDetail: `${apiHost}/webapi/admin/notify/detail`,
    handleMessage: `${apiHost}/webapi/admin/notify/handleMessage`,
    notifyRead: `${apiHost}/webapi/admin/notify/update`,
}
